<template>
  <div>
    <CRow>
      <CCol md="12" style="text-align: center;">
        Colibrí & Tú - Boutique<br /><br />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CInput class="searcher"
          style="font-size; 18px;"
          :lazy="false"
          id="name"
          name="name"
          v-model="searching"
          v-on:keyup.enter="search"
          placeholder=""
          autocomplete="given-name"
          invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
        />
        <CButton @click="search" color="success" style="float: right;">Buscar</CButton>
      </CCol>
    </CRow>

    <ProductDetailsModal ref="productDetailsModal"></ProductDetailsModal>
  </div>
</template>
<script>

import ProductDetailsModal from '../../components/products/ProductDetailsModal.vue'

import store from '../../store'
import ws from '../../services/products';

export default {
  name: 'Home',
  components: {
    ProductDetailsModal
  },
  data () {
    return {
      searching: '',
    }
  },
  mounted: async function() {

  },
  methods: {
    async search () {
      this.loading();
      let response = await ws.getSearching(this.searching);

      if(response.type == "success"){
        let products = response.data;

        this.showProduct(products);

        this.loaded();
      }
    },
    showProduct (products) {
      this.$refs.productDetailsModal.showModal(products);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
